/* :root {
  --font-color: #333;
  --background-color: #eee;
  --link-color: cornflowerblue;
}

[dark-theme="dark"] {
  --font-color: #eee;
  --background-color: #333;
  --link-color: lightblue;
} */

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  color: #696969;
}

.anchorTagContact {
  color: #696969;
  cursor: pointer;
  text-decoration: none;
}

.divAboutContainer {
  margin-top: 3rem;
}

.divAboutContainer,
.divServicesContainer,
.divRetreadingContainer,
.divFeedbackContainer,
.divCertificatesContainer,
.divOtherServicesContainer {
  width: 90%;
  /* background: #8578de2f; */
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 10px;
  /* border: 1px solid rgba(110,107,110,0.61); */
  -webkit-box-shadow: 5px 5px 5px 2px rgba(110,107,110,0.61);
  -moz-box-shadow: 5px 5px 5px 2px rgba(110,107,110,0.61);
  box-shadow: 5px 5px 5px 2px rgba(110,107,110,0.61);
}

.divBrochure {
  display: flex;
  justify-content: evenly;
  align-content: center;
}

.divBrochure a, .divOtherServicesContainer a, .divFeedbackDiv button {
  background: #0d6efd;
  color: white;
  font-size: clamp(0.8rem, 1.2vw, 1.5rem);
  text-decoration: none;
  cursor: pointer;
}

.divBrochure a:hover, .divOtherServicesContainer a:hover, .divFeedbackDiv button:hover {
  background: white;
  color: black;
}

.divServicesContainer div p {
  padding-left: 3rem;
  /* border: 2px solid red; */
}

.anchorTagContact:hover {
  color: white;
  text-decoration: underline;
}

.title {
  color: #cc0836;
  font-size: clamp(1.4rem, 2.3vw, 2.7rem);
  text-align: start;
  padding-left: 1rem;
  padding-bottom: 1.5rem;
  transition: text-decoration 2s ease;
}

.title::after {
  content: "";
  width: 0px;
  height: 2px;
  display: block;
  /* background: #2f0ffc; */
  background: #cc0836;
  transition: 300ms;
}

.title:hover::after {
  width: 100%;
}

.logoPicture {
  width: 6%;
  display: inline;
  background: #fff;
  margin-right: 1rem;
  border-radius: 6px;
}

.servicesFlexDirectionPhoto {
  width: 70%;
  /* background: #2f0ffc; */
  background: #cc0836;
  /* background: #fff829; */
  transform: rotate(-3deg);
  border-radius: 10px;
  margin: 2rem;
}

.servicesFlexDirectionPhoto img {
  transform: rotate(-4deg);
}

.aboutFlexDirectionPhoto {
  width: 80%;
  /* background: #2f0ffc; */
  /* background: #fff829; */
  background: #cc0836;
  transform: rotate(-3deg);
  border-radius: 10px;
  margin: 0 2rem 2rem 0;
}

.aboutFlexDirectionPhoto img {
  transform: rotate(-4deg);
}

.retreadingFlexDirectionPhoto {
  width: 80%;
  /* background: #2f0ffc; */
  background: #cc0836;
  /* background: #fff829; */
  transform: rotate(3deg);
  border-radius: 10px;
}

.retreadingFlexDirectionPhoto img {
  transform: rotate(4deg);
}

.aboutFlexDirectionParagraph,
.retreadingFlexDirectionParagraph {
  width: 90%;
}

.servicesFlexDirectionParagraph_Offer_Services b {
  font-size: clamp(1.1rem, 1.2vw, 1.4rem);
}

.divTagbandBigScreen {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: black;
  font-size: 1rem;
  padding: 0.4rem;
}

.divTagbandSmallScreen {
  display: none;
}

.aboutFlexDirectionParagraph p,
  .servicesFlexDirectionParagraph p,
  .retreadingFlexDirectionParagraph p {
    font-size: clamp(1rem, 1.3vw, 1.6rem);
    line-height: 1.6;
  }

  .aboutFlexDirectionPhoto img,
  .servicesFlexDirectionPhoto img,
  .retreadingFlexDirectionPhoto img {
    cursor: pointer;
    transition: all 0.2s linear;
  }

  .aboutFlexDirectionPhoto img:hover,
  .servicesFlexDirectionPhoto img:hover,
  .retreadingFlexDirectionPhoto img:hover {
    transform: rotate(0deg);
    transform: scale(1.05);
  }

.translator {
  width: 6rem;
}
.translator button, .DualMode button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: gray;
  color: #fff;
  border: none;
}

.translator button b {
  text-align: center;
}

.DualMode,
.translator {
  background: transparent;
  margin: 0 1px;
}

.DualMode button:hover, .translator button:hover {
  color: gray;
  background: white;
}

.DualMode {
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgFeedback {
  animation: rotated 35s linear 2s infinite;
}

.imgFeedback img {
  width: 70%;
}

@keyframes rotated {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.divCertificatesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.divCertificatesContainer > .div2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  p {
    font-size: 0.9rem;
  }
}

@media (min-width: 768px) {
  p {
    width: 95%;
  }

  .divContactLinks h3 {
    font-size: clamp(1.4rem, 2.3vw, 2.7rem);
  }

  .servicesFlexDirection {
    flex-direction: row;
  }

  /* .divCertificatesContainer .div2 {
    justify-content: space-evenly;
  } */

  .divCertificatesContainer .div2 img {
    width: 8rem;
    background: #fff;
    border-radius: 10%;
  }

  .SocialIcons > .ikons {
    color: gray;
    transition: transform 0.1s linear;
    margin: 0 5px;
  }

  .SocialIcons > .ikons:hover {
    color: #fff;
    transform: scale(1.2);
  }

  .divContactContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* flex-direction: column; */
    text-align: start;
    /* border: 1px solid white; */
  }
}

@media (max-width: 767px) {
  .title {
    padding-bottom: 1.1rem;
  }

  .logoPicture {
    width: 12%;
  }

  .servicesFlexDirection {
    flex-direction: column;
  }

  .title:hover::after {
    width: 70%;
  }

  .divServicesContainer div p {
    padding: 0 auto;
  }

  .divTagbandBigScreen {
    display: none;
  }

  .divTagbandSmallScreen {
    display: flex;
    position: fixed;
    top: 28rem;
    right: -18.8rem;
    justify-content: space-around;
    align-items: center;
    background: #cc0836;
    font-size: 1rem;
    padding: 0.2rem;
    border-radius: 0.2rem;
    z-index: 999;
    cursor: pointer;
    transition: right 0.3s linear;
  }

  .divTagbandSmallScreen:hover {
    right: -0.2rem;
  }

  .divTagbandSmallScreen > div {
    margin: 0 3px;
  }

  .divTagbandSmallScreen .DualMode {
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .divTagbandSmallScreen .DualMode button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .divTagbandSmallScreen .DualMode button b {
    width: 4rem;
  }

  .divTagbandSmallScreen .translator {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .divTagbandSmallScreen .translator button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .divTagbandSmallScreen .translator button b {
    width: 4rem;
  }

  .divTagbandSmallScreen > .SocialIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 5.5rem;
    padding-bottom: 3px;
  }

  .imgFeedback {
    max-width: 70%;
  }

  .divCertificatesContainer .div2 img {
    width: 6rem;
    background: #fff;
    border-radius: 10%;
  }

  .divContactLinks {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: start;
  }
}

@media (max-width: 567px) {
  .logoPicture {
    width: 16%;
  }

  .title:hover::after {
    width: 80%;
  }

  .divAboutContainer {
    margin-top: 1.3rem;
  }

  .divAboutContainer,
  .divServicesContainer,
  .divRetreadingContainer,
  .divFeedbackContainer,
  .divOtherServicesContainer,
  .divCertificatesContainer {
    background: #fff;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 0;
  }

  .divTagbandBigScreen {
    display: none;
  }

  .divTagbandSmallScreen {
    display: inline-flex;
    position: fixed;
    top: 22rem;
    right: -14.7rem;
    /* right: 1rem; */
    justify-content: space-around;
    align-items: center;
    background: #cc0836;
    font-size: 0.7rem;
    padding: 0.2rem;
    z-index: 999;
  }

  .divTagbandSmallScreen:hover {
    right: -0.1rem;
  }

  .divTagbandSmallScreen .DualMode {
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .DualMode button, .translator button {
    padding: 2px 4px;
  }

  .divTagbandSmallScreen .translator {
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .divTagbandSmallScreen > .SocialIcons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
  }

  .SocialIcons > .ikons {
    font-size: 1.2rem;
  }

  .divCertificatesContainer .div2 img {
    background: #fff;
    border-radius: 10%;
  }
}
